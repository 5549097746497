import { CreatePermissionPayload, Permission } from '@/models/Permission';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import draggable from 'vuedraggable';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';

@Component<CreatePermissionDialog>({
  components: {
    DefaultDialog,
    draggable,
  },
})
export default class CreatePermissionDialog extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @Prop()
  protected reportType!: string;
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  protected permissionData: CreatePermissionPayload = {};

  protected isLoading = false;

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  // #endregion

  // #region Class methods
  protected close(): void {
    this.$emit('input', false);
  }

  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async submit(): Promise<void> {
    if (! this.permissionData) { return; }
    this.isLoading = true;
    await new Permission()
      .create(this.createPayload)
      .then(() => {
        this.$emit('reload');
        this.close();
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get isDisabled() {
    return ! this.permissionData || ! this.permissionData.name || ! this.permissionData.slug || ! this.permissionData.description;
  }

  protected get createPayload(): CreatePermissionPayload {
    return {
      name: this.permissionData?.name || '',
      slug: this.permissionData?.slug || '',
      description: this.permissionData?.description || '',
    };
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
