var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [_vm._v("Permissie toevoegen")]),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? [
                    _c("loader-card", {
                      attrs: { type: "spinner--center", flat: true }
                    })
                  ]
                : _vm._e(),
              !_vm.isLoading
                ? [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Naam" },
                              model: {
                                value: _vm.permissionData.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.permissionData, "name", $$v)
                                },
                                expression: "permissionData.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Slug" },
                              model: {
                                value: _vm.permissionData.slug,
                                callback: function($$v) {
                                  _vm.$set(_vm.permissionData, "slug", $$v)
                                },
                                expression: "permissionData.slug"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Beschrijving" },
                              model: {
                                value: _vm.permissionData.description,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.permissionData,
                                    "description",
                                    $$v
                                  )
                                },
                                expression: "permissionData.description"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "v-layout",
                { attrs: { "justify-end": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: { click: _vm.close }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "MiButton",
                          {
                            attrs: {
                              disabled: _vm.isDisabled,
                              color: "primary",
                              small: "true"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.submit($event)
                              }
                            }
                          },
                          [_vm._v("Toevoegen")]
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }