import { ReportType } from '@/models/ReportType';
import { Permission } from '@/models/Permission';
import { AxiosError } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import CreatePermissionDialog from './CreatePermissionDialog/CreatePermissionDialog.vue';

@Component<Permissions>({
  components: {
    CreatePermissionDialog,
  },
})
export default class Permissions extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  public $pageTitle = 'Permissies';

  protected isLoading = false;

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  };

  protected isCreatingPermission = false;

  protected selectedPermission: Permission | null = null;

  protected tableFilters = {};
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  protected created(): void {
    this.reRoute();
  }

  protected async mounted(): Promise<void> {
    this.reRoute();
  }
  // #endregion

  // #region Class methods

  protected refreshDatatable(): void {
    if (this.$refs.permissionsDatatable) {
      (this.$refs.permissionsDatatable as DataTable).refresh();
    }
  }

  protected reRoute(): void {
    if (! this.$store.state.Auth.isAtabix) {
      this.$router.push('/');
    }
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get tableOptions(): Object {
    return {
      model: new Permission().limit(25).filter(this.datatableFilters).sort('sort_order', 'ASC'),
      headers: [
        {
          text: 'Naam',
          value: 'name',
          class: 'tw-font-bold tw-cursor-pointer',
          action: (permission: Permission) => {
            this.$router.push(`/permissies/${permission.id}`);
          },
        },
        {
          text: 'Beschrijving',
          value: 'description',
          width: '40%',
        },
        {
          text: 'Slug',
          value: 'slug',
        },
        {
          text: 'Categorie',
          value: 'category',
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'remove_red_eye',
          action: (dataTable: DataTable, permission: Permission) => {
            this.$router.push(`/permissies/${permission.id}`);
          },
          tooltip: 'Aanpassen',
        },
      ],
      filter: [],
    };
  }

  protected get datatableFilters(): any {
    return this.tableFilters;
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
