var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("Permissies")])
          ]),
          _c(
            "v-flex",
            { staticClass: "relative", attrs: { xs6: "", "text-right": "" } },
            [
              _c(
                "div",
                { staticClass: "elementTitleButton absolute" },
                [
                  _c(
                    "MiButton",
                    {
                      staticClass: "mx-1",
                      attrs: { color: "primary", small: "true", icon: "add" },
                      nativeOn: {
                        click: function($event) {
                          _vm.isCreatingPermission = true
                        }
                      }
                    },
                    [_vm._v(" Permissie toevoegen ")]
                  )
                ],
                1
              )
            ]
          ),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c("div", { staticClass: "elementPanel elementPanel--spaced" }, [
              _c(
                "div",
                { staticClass: "tw-w-full" },
                [
                  _c("DataTable", {
                    ref: "permissionsDatatable",
                    attrs: {
                      options: _vm.tableOptions,
                      visibility: _vm.visibility
                    }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _vm.isCreatingPermission
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "80%", persistent: "" },
              model: {
                value: _vm.isCreatingPermission,
                callback: function($$v) {
                  _vm.isCreatingPermission = $$v
                },
                expression: "isCreatingPermission"
              }
            },
            [
              _c("CreatePermissionDialog", {
                on: { reload: _vm.refreshDatatable },
                model: {
                  value: _vm.isCreatingPermission,
                  callback: function($$v) {
                    _vm.isCreatingPermission = $$v
                  },
                  expression: "isCreatingPermission"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }