import { Component, Vue } from 'vue-property-decorator';
import { Role } from '@/models/Role';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import CreateRoleDialog from '@/views/Settings/Roles/CreateRoleDialog/CreateRoleDialog.vue';

@Component<Roles>({
  components: {
    CreateRoleDialog,
  },
})

export default class Roles extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  public $pageTitle = 'Rollen';

  protected visibility: TableVisibility = {
    checkboxes: false,
    title: false,
    search: false,
  };

  protected isCreatingRole = false;

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  protected async mounted() {
    await this.fetchPermissions();
    this.emitBreadcrumb();
  }
  // #endregion

  // #region Class methods

  protected refreshDatatable() {
    if (this.$refs.roleDatatable) {
      (this.$refs.roleDatatable as DataTable).refresh();
    }
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rollen' },
        ],
      });
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */
  protected async fetchPermissions(): Promise<void> {
    // let permissions: Permission[] | null = null;

    // try {
    //   permissions = await new Permission()
    //     .all();
    // } catch (error) {
    //   //
    // }

    // if (permissions) {
    //   this.permissions = this.groupPermissions(permissions);
    // }
  }
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get tableOptions() {
    return {
      model: new Role(),
      headers: [
        {
          text: 'Naam',
          value: 'name',
          class: 'tw-font-bold',
        },
      ],
      actions: [
        {
          type: 'edit',
          label: 'view',
          icon: 'remove_red_eye',
          tooltip: 'Bekijk rol',
          action: '/rollen/{id}',
        },
      ],
      filter: [],
    };
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
