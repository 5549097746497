import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/models/User';
import { groupBy, sortBy, Dictionary, flatMap } from 'lodash';
import { Permission } from '@/models/Permission';
import { PermissionSlug } from '@/support/PermissionSlug';
import { Role } from '@/models/Role';

import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<CreateRoleDialog>({

})

export default class CreateRoleDialog extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected payload = {
    name: '',
  };

  protected isLoading = false;
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  protected async mounted() {

  }
  // #endregion

  // #region Class methods

  protected close() {
    this.$emit('input', false);
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */
  protected async create(): Promise<void> {
    const role = await new Role()
      .create(this.payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (role) {
      this.close();
      this.$emit('isCreated');
    }
  }
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get isDisabled() {
    return ! this.payload.name;
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
